<template>

  <div class="evolvePage">
    <v-app id="vApp">
      
    <!-- Evolve Overlay -->
    <!--
      <EvolveOverlay
        :category="overlayCategory"
        :context-category="overlayContextCategory"
        :parent-category="overlayParentCategory"
        :specificity-level="overlaySpecificityLevel"
        :overlay-type="overlayType"
        :action-mean-category ="overlayActionMeanCategory"
        :parent-view="'Evolve'"
        id="evolveOverlay"
        v-on:overlay-off="overlayOff"
        />
        -->
    <HeroBanner 
      :intent-mean-category="intentMeanCategory"
      :image-url="heroImageUrl"
      :image-credit="heroCredit"
      :parent-view="'Evolve'" 
    />
    <div class="contentWrapper">
      <div class="leftRail">

        <!--left Rail-->

      </div>
      <div class="middleRail">
        
        <div class="buttonsHolder">
          <div 
            @click="modifyRoutine()" class="addToRoutineButton button2"
            v-if="this.$store.state.auth.role === 'superDuperAdmin'"
            >
            Modify this Routine
          </div>
          <div @click="addRoutine()" class="addToRoutineButton button1">
            Follow this Routine
          </div>
        </div>
        <!-- Attention Management Buttons -->
        <!--
        <CategoryHeroButtons
          :category="category"
          :context-category="contextCategory"
          :parent-category="parentCategory"
          :specificity-level="specificityLevel"
          :parent-view="'Evolve'"
          v-on:overlay="showOverlay"
          />
        -->
        
        <div class="caseStudyHolder">
          <br /><br />
          <div class="headlineDiv">
            {{ headline }}
          </div>
          <br />
          <br/>
          <div class="starDivider" ref="star">
            <img :src="require('@/assets/smallStar_grey_filled.png')" alt="heedi" class="starImg">&nbsp;&nbsp;&nbsp;
            <img :src="require('@/assets/smallStar_grey_filled.png')" alt="heedi" class="starImg">
          </div>
          <br />
          <div class="subheadline">
            {{ subheadline }}
          </div>
          <div class="description" v-html="summary">
            {{ summary }}
          </div>
          <br><br>
          <div class="imageGalleryContainer">
            <div class="imageContainer" v-if="content1Img">
              <img class="contentImage" :src="content1Img">
            </div>
            <div class="imageContainer" v-if="content2Img">
              <img class="contentImage" :src="content2Img">
            </div>
          </div>
          <br>
          <br>
          <br>
          <br>
          <div class="relatedRoutinesText">
            Other routines related to <b>{{ intentMeanCategory }}</b>:
          </div>
          <br><br>
          <RelatedRoutines
          :intent-mean-category="intentMeanCategory"
          />


        </div>
        <br>
        <br>
        <br>
        
        
        <!--
        <ActionChains 
          :category="category"
          :context-category="contextCategory"
          :specificity-level="specificityLevel"
          :parent-category="parentCategory"
          :parent-view="'Evolve'" 
          v-on:overlay="showOverlay"
          />
          -->

          <!--
          <TipJar 
          :category="category" 
          :parent-category="parentCategory" 
          :parent-view="'Evolve'" 
          />
          -->

      </div>
      <div class="rightRail">
        <!--right Rail-->
      </div>
    </div>
    <VueFooter />
    </v-app>
  </div>
</template>
<script>
  import '@/plugins/vuetify'
  import feedbackModule from '@/store/feedback'
  import userModule from '@/store/user'
  import signinModule from '@/store/signin'
  //import experienceModule from '@/store/experience'
  import categoryModule from '@/store/category'
  import cmsModule from '@/store/cms'
  import settingsModule from '@/store/settings'
  import tipModule from '@/store/tip'
  import routineModule from '@/store/routine'
  import VueFooter from '@/components/VueFooter'
  import HeroBanner from '@/components/HeroBanner'
  import RelatedRoutines from '@/components/RelatedRoutines'
  // import ActionChains from '@/components/ActionChains'
  //import TipJar from '@/components/TipJar'
  //import EvolveOverlay from '@/components/EvolveOverlay'
  // import DailyRoutineMaker from '@/components/routines/DailyRoutineMaker'
  // import ExerciseRoutineMaker from '@/components/routines/ExerciseRoutineMaker'
  // import CessationRoutineMaker from '@/components/routines/CessationRoutineMaker'
  // import CategoryHeroButtons from '@/components/CategoryHeroButtons'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: "evolve",
    components: {
      VueFooter,
      HeroBanner,
      RelatedRoutines
      //ActionChains,
      //TipJar,
      //DailyRoutineMaker,
      //ExerciseRoutineMaker,
      //CessationRoutineMaker,
      //CategoryHeroButtons,
      //EvolveOverlay,
    },
    data () {
      return {
        category: null,
        intentMeanCategory: null,
        contextCategory: null,
        specificityLevel: null,


        overlayType: null,
        overlayCategory: null,
        overlayContextCategory: null,
        overlayParentCategory: null,
        overlaySpecificityLevel: null,
        overlayActionMeanCategory: null,

        routineId: null,
        routineType: null,
        routineTitle: null,

        headline: null,
        subheadline: null,
        description: null,
        content1Img: null,
        content2Img: null,
        summary: null,

        heroImageUrl: null,
        heroCredit: null,


      }
    },
    computed: {
      ...mapGetters('cms', [
        'tipJarContent',
        'actionChainsContent'
      ]),
      ...mapGetters('category', [
        'currentCategory',
        'currentContextCategory',
        'currentParentCategory',
        'currentCtaType',
        'currentSpecificityLevel',
      ]),
      ...mapGetters('auth', [
        'profile',
        'loggedIn',
        'role'
      ])

      
    },
    watch: {
      
    },
    methods: {
      ...mapActions('signin', [
        'autoSignIn'
      ]),
      ...mapActions('category', [
        'getValidCategory',
        'setCategoryOverlayData'
      ]),
      ...mapActions('search', [
        'setShowSearch',
        'setShowSearchIcon',
      ]),
      ...mapActions('routine', [
        'getRoutineData'
      ]),
      ...mapActions('auth', [
        'setEditMode'
      ]),
      showOverlay (payload) {

        //If ctaType a routine, going to send user over to the Routine page
        if(payload.ctaType == 'ADD_TO_ROUTINE') {
          this.$router.push('/routine/add/something/'+payload.parentCategory)
          return
        }else if(payload.ctaType == 'ADD_EXERCISE_TO_ROUTINE') {
          this.$router.push('/routine/create/exercise/'+payload.parentCategory)
          return
          
        }else if(payload.ctaType == 'ADD_COOKING_TO_ROUTINE') {
          this.$router.push('/routine/create/cooking/'+payload.parentCategory)
          return
          
        }else if(payload.ctaType == 'ADD_EVERYDAY_HABIT_TO_ROUTINE') {
          this.$router.push('/routine/create/everyday_habit/'+payload.parentCategory)
          return
          
        }else if(payload.ctaType == 'CESSATION_PROGRAM') {
          this.$router.push('/routine/create/cessation/'+payload.parentCategory)
          return
        }
        this.overlayCategory = payload.category
        this.overlayType = payload.ctaType
        this.overlayContextCategory = payload.contextCategory
        this.overlayParentCategory = payload.parentCategory
        this.overlaySpecificityLevel = payload.specificityLevel
        this.overlayActionMeanCategory = (payload.actionMeanCategory) ? payload.actionMeanCategory : null
        window.scrollTo(0,0)
        document.getElementById("evolveOverlay").style.display = "block";
      },
      overlayOff () {
        this.overlayCategory = null
        this.overlayType = null
        this.overlayContextCategory = null
        this.overlayParentCategory = null
        this.overlaySpecificityLevel = null
        this.overlayActionMeanCategory = null
        window.scrollTo(0,0)
        document.getElementById("evolveOverlay").style.display = "none";
      },
      modifyRoutine() {
        this.$router.push("/admin/modify/"+this.routineType+"/"+this.routineId)
      },
      addRoutine() {
        this.setEditMode(true)
        //this.$router.push('/console/add/'+this.routineType+'/'+this.routineId)
        this.$router.push('/console/initialize/'+this.routineType+'/'+this.routineId+'/'+encodeURIComponent(this.routineTitle))
      }
      
    },
    beforeCreate () {
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.feedback) this.$store.registerModule('feedback', feedbackModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.routine) this.$store.registerModule('routine', routineModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      if(!this.$store.state.tip) this.$store.registerModule('tip', tipModule)
      if(!this.$store.state.cms) this.$store.registerModule('cms', cmsModule)
      if(!this.$store.state.category) this.$store.registerModule('category', categoryModule)

      
    },
    async created () {
      window.scrollTo(0,0)
      /* eslint-disable no-undef */
      gtag('config', 'G-H9RD8W4MLR', {'page_path': '/evolve'});
      await this.autoSignIn()
      this.setShowSearchIcon(true)
      //var routineType  = (this.$route.params.routineType) ? this.$route.params.routineType : null
      var category  = (this.$route.params.category) ? this.$route.params.category : null
      var routineId  = (this.$route.params.routineId) ? this.$route.params.routineId : null


      if(category) {
        this.intentMeanCategory = category.replace(/_/g, ' ');
      }

      if(routineId) {
        this.routineId = routineId


        this.getRoutineData(routineId).then((routineData) => { 
          
          this.headline = routineData.marketingContent.headline
          this.summary = routineData.marketingContent.summary
          this.subheadline = routineData.marketingContent.subheadline
          this.description = routineData.marketingContent.description
          this.content1Img = routineData.marketingContent.images.detailPage.contentImages.contentImg1
          this.content2Img = routineData.marketingContent.images.detailPage.contentImages.contentImg2
          this.routineType = routineData.routineTemplate.routineType
          this.routineTitle = routineData.routineTemplate.routineTitle
          this.intentMeanCategory = routineData.routineTemplate.intentMeanCategory
          this.heroImageUrl = routineData.marketingContent.images.detailPage.calloutImage.url
          this.heroCredit = routineData.marketingContent.images.detailPage.calloutImage.credit

        })
        
      }
    },

    mounted () {
      //this.category  = this.$route.params.catName
      this.setShowSearch(false)
      
      
    },
    updated () {
      //this.overlayTitle = "test"
    },
    
  }
</script>
<style scoped>




#vApp {
 background-color: transparent;
}
.contentWrapper {
  box-sizing: border-box;
  background-color: transparent;
}
.leftRail {
  box-sizing: border-box;
  vertical-align: top;
  background-color: transparent;
  display: none;
  min-height: 940px;  /*this setting important to prevent double scrollbars*/

}
.middleRail {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  background-color: transparent;
  margin: 0 auto;
  min-height: 940px; /*this setting important to prevent double scrollbars*/
}
.rightRail {
  vertical-align: top;
  box-sizing: border-box;
  background-color: transparent;
  display: none;
  min-height: 940px; /*this setting important to prevent double scrollbars*/
}
.buttonsHolder {
  background-color:  rgb(67,67,66);
  text-align: center;
  padding: 14px;
}
.addToRoutineButton {
  border: none;
  color: white;
  font-weight: 600;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
}
.button1 { 
  background-color: orange;
}
.button2 { 
  background-color: rgb(228, 228, 228);
  color: rgb(129, 129, 129);
}

.caseStudyHolder {
  background-color: white;
  min-height: 900px;
  width: 100%;
  text-align: center;
  padding: 15px;
  
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.starDivider {
  display: inline-block;
  margin: 0 auto;
  width: 100px;
}
.starImg {
  width: 30px;
  opacity: 0.8;
}

.headlineDiv {
  font-family: 'Kreon', serif;
  font-weight: 700;
  font-size: 2.7em;
  display: inline-block;
  max-width: 650px;
}
.subheadline {
  font-family: 'Quicksand', sans-serif;
  display: inline-block;
  font-weight: 400;
  font-size: 1.7em;
  padding: 20px;
  max-width: 320px;
}
.description {
  font-family: 'Quicksand', sans-serif;
  margin: 0 auto;
  text-align: left;
  font-size: 1.3em;
  margin-top: 30px;
  max-width: 500px;
}


.relatedRoutinesText {
  font-family: 'Kreon', serif;
  font-weight: 700;
  font-size: 1.6em;
  display: inline-block;
  max-width: 650px;
}

.imageGalleryContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
  margin: 0 auto;
}
.contentImage {
  height: 370px;
  width: 370px;
  object-fit: cover;
}



@media screen and (min-width: 360px) {
  .buttonWrapper {
    width: 100%;
  }
  .panel {
    padding: 8px;
  }
  .lineBox {
    font-size: 1em;
    min-height: 82px;
  }
  .btnIconHolder {
    font-size: 1.6em;
    padding: 2px;
  }
  .btnTitleHolder {
    padding-left: 5px;
    font-weight: 500;
  }

}


@media screen and (min-width: 400px) {
  

}
@media screen and (min-width: 500px) {
  .buttonWrapper {
    width: 80%;
  }
  .lineBox {
    font-size: 1.1em;
    padding: 14px;
    padding-top: 14px;
  }
  .panel {
    padding: 14px;
  }

  .contentImage {
    height: 250px;
    width: 250px;
    border-radius: 7px;
  }
  .imageGalleryContainer {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 2px;
    max-width: 600px;
  }
}



@media screen and (min-width: 1000px) {
  .lineBox {
    font-size: 1.3em;
    min-height: 60px;
  }
  
}


@media screen and (min-width: 1100px) {
  
  .leftRail {
    display: inline-block;
    width: 15%;
  }
  .middleRail {
    width: 70%;
  }
  .rightRail {
    display: inline-block;
    width: 15%;
  }

}


@media screen and (min-width: 1300px) {
  .leftRail {
    width: 18%;
  }
  .middleRail {
    width: 64%;
  }
  .rightRail {
    width: 18%;
  }
}


@media screen and (min-width: 1400px) {
  .leftRail {
    width: 17%;
  }
  .middleRail {
    width: 66%;
  }
  .rightRail {
    width: 17%;
  }
}





@media screen and (min-width: 1600px) {
  .leftRail {
    width: 22%;
  }
  .middleRail {
    width: 56%;
  }
  .rightRail {
    width: 22%;
  }
}
</style>