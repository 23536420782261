<template>
  <div class="wrapper">
    <div class="hero">
      
        <div class="hero-overlay">
          <div class="hero-credit" v-html="imageCredit">
            {{ this.imageCredit }}
          </div>
        </div>
        <img class="hero-img" id="hero-img" v-bind:src="this.imageUrl" />
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import imageModule from '@/store/image'

  export default {
    name: "HeroBanner",
    components: {
      
    },
    props: {
      parentView: {
        type: String
      },
      intentMeanCategory: {
        type: String
      },
      imageUrl: {
        type: String
      },
      imageCredit: {
        type: String
      }
      
    },
    data () {
      return {
        heroImageSrc: '',
        heroCreditLink: '',
        heroCreditText: '',
        categoryTitle: '',
        bannerComplete: false,
        
      }
    },
    computed: {
      ...mapGetters('category', [
        'currentCategory',
        'currentParentCategory'
      ]),
      
      
      
    },
    watch: {
      
      intentMeanCategory: function(newValue) {
        if(newValue && !this.bannerComplete) {  //I have incoming parameters that arrive at different times.
          
          // var newCat = this.intentMeanCategory.replace(/_/g, ' ');
          //var newCatSlug = this.category.replace(/\s+/g, '-').toLowerCase();
          //var newIntentMeanCatSlug = this.intentMeanCategory.replace(/\s+/g, '-').toLowerCase();
          //var obj = {
            //'category': this.category,
          //  'intentMeanCategory': newValue,
            //'categorySlug': newCatSlug,
          //  'intentMeanCategorySlug': newIntentMeanCatSlug,
            //'categoryType': "intentMean"
          //}
          
          // this.categoryTitle = newCat;
          //this.validCategory = true;
          //this.getHeroImage(obj).then((hero) => {   //If its a bad category, there will be no hero image
            
            //this.heroImageSrc = hero.src
            //this.heroCreditText = hero.credit
            //this.heroCreditLink = hero.link
            this.bannerComplete = true
            //show the image
            document.getElementById('hero-img').style.display = "block";
            
          //})
        } 
      }
      
    },
    methods: {
      ...mapActions('image', [
        'getHeroImage'
      ]),

  
    },
    beforeCreate () {
      
      if(!this.$store.state.image) this.$store.registerModule('image', imageModule)

      
    },
    created () {
      //this.fetchTheData();
      //this.searchLabelComputer();
      
    
    },
    async mounted () {
      //this.$refs.searchBox.focus()
        
          
          

      
    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
  overflow: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}

.hero {
  position: relative;
  width: 100%;
  height: 75px;
  background-color: rgb(0, 0, 0);

}
.hero-img {
  display: none;
  background: rgba(34, 34, 34, 1);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 30px left;
  object-position: 30px left;
}
.hero-title {
  box-sizing: border-box;
  position: absolute;
  color: rgb(37, 37, 37);
  font-size: 1.20em;
  font-weight: 700;
  background-color: rgba(255, 255, 255, .70);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 12px;
  z-index: 7;
  top: 15%;
  right: 4%;
  margin-right: 12px;
  cursor: pointer;
  visibility: hidden;
}


.hero-overlay {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: 6;
}
.hero-credit {
  padding-bottom: 3px;
  position: absolute;
  bottom: 0;
  left: 1em;
  color: rgb(255, 255, 255);
  font-size: .6em;
  font-weight: 400;
}
.hero:hover .hero-overlay {
  display: inline-block;
}

@media screen and (min-width: 400px) {
  .hero {
    height: 85px;
  }

}

@media screen and (min-width: 600px) {
  .hero {
    height: 95px;
  }
  .hero-title {
    top: 15%;
    right: 4%;
    margin-right: 12px;
    font-size: 1.40em;
    font-weight: 700;
  }
  .hero-credit {
    padding-bottom: 3px;
    font-size: .7em;
  }
}

@media screen and (min-width: 800px) {
  .hero {
    height: 110px;
  }
  .hero-title {
    top: 15%;
    right: 4%;
    margin-right: 12px;
    font-size: 1.60em;
    padding: 6px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .hero-credit {
    padding-bottom: 3px;
    font-size: .85em;
  }
}

@media screen and (min-width: 1000px) {
  .hero {
    height: 120px;
  }
  .hero-title {
    top: 17%;
    right: 7%;
    margin-right: 12px;
    font-size: 1.90em;
  }
  .hero-credit {
    padding-bottom: 3px;
    font-size: 1em;
    padding: 7px;
  }
}

@media screen and (min-width: 1300px) {
  .hero {
    height: 130px;
  }
  .hero-title {
    top: 19%;
    right: 11%;
    margin-right: 12px;
    font-size: 2.1em;
  }
  .hero-credit {
    padding-bottom: 3px;
    font-size: 1.2em;
  }

}



</style>