import db from '@/db'
import firebase from '@/firebase'

import {
    SET_TIP,
    RESET_TIP,
    SET_TIP_LOADING,
    PUSH_TIP,
    RESET_TIP_LIST,
    REMOVE_TIP
  } from './mutations'
  
  const state = {
    newTip: {
      dateCompleted: null,
      dateCreated: null,
      dateUpdated: null,
      experienceState: null,
      intentMeanCategory: null,
      intentMeanParentCategory: null,
      minderId: null,
      momentId: null,
      tipStatement: null,
      tipStimulusQuestion: null, 
      tipTheme: null,
    },
    tips: {
      0: [],
      1: [],
      2: [],
      3: [],
    },
    loading: false
    
  }
  
  const getters = {
    newTip: ({newTip}) => newTip,
    tips: ({tips}) => tips,
    loading: ({loading}) => loading
    
  }
  
  const mutations = {
    [SET_TIP_LOADING] (state, bool) {
      state.loading = bool
    },
    [SET_TIP] (state, payload) {
      state.newTip.dateCompleted = payload.dateCompleted
      state.newTip.dateCreated  = payload.dateCreated
      state.newTip.dateUpdated  = payload.dateUpdated
      state.newTip.experienceState  = payload.experienceState
      state.newTip.intentMeanCategory = payload.intentMeanCategory
      state.newTip.intentMeanParentCategory = payload.intentMeanParentCategory
      state.newTip.momentId  = payload.momentId
      state.newTip.tipStatement  = payload.tipStatement
      state.newTip.tipStimulusQuestion  = payload.tipStimulusQuestion
      state.newTip.tipTheme  = payload.tipTheme
    },
    [RESET_TIP] (state) {
      state.newTip.dateCompleted = null
      state.newTip.dateCreated  = null
      state.newTip.dateUpdated  = null
      state.newTip.experienceState  = null
      state.newTip.intentMeanCategory = null
      state.newTip.intentMeanParentCategory = null
      state.newTip.momentId  = null
      state.newTip.tipStatement  = null
      state.newTip.tipStimulusQuestion  = null
      state.newTip.tipTheme  = null
    },
    [PUSH_TIP] (state, payload) {
      state.tips.push(payload.tip)
    },
    [REMOVE_TIP] (state, payload) {
      if (state.tips.length >= 1) {
        state.tips.splice(payload.itemIndex, 1)
      }
    },
    [RESET_TIP_LIST] (state) {
        state.tips = []
    }
  }
  
  const actions = {

    resetTip({commit}) {
        commit(RESET_TIP)
    },
    
    

    async removeTip ({commit}, payload) {
      firebase.auth().onAuthStateChanged(async (user) => {  
        if (user) {   
          commit(REMOVE_TIP, payload)
          await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('intent_mean_categories').doc(payload.intentMeanCategory).collection("userTips").doc(payload.tipId).delete()
        }
      })
    },
    async createTip ({commit,state, dispatch}, payload) {
      firebase.auth().onAuthStateChanged(async (user) => {  
        if (user) {
            commit(SET_TIP, payload)
            var newDate = new Date()
            //Adding tip to the mean_categories subcollection:  userTips
            var ref2 = db.collection('taxonomies').doc('v1_2020_taxonomy').collection('intent_mean_categories').doc(payload.intentMeanCategory).collection("userTips").doc()
            await ref2.set({
              ...state.newTip,
                'tipId': ref2.id, 
                'parentTipId': ref2.id,
                'userUid': user.uid,
            })
            
            await dispatch('statement/createStatement', {
              'dateCreated': newDate,
              'dateUpdated': newDate,
              'intentMeanCategory': payload.intentMeanCategory,   //the createStatement function current ignores this, so this just extra data in the object
              'intentMeanParentCategory': payload.intentMeanParentCategory,  //the createStatement function current ignores this
              'momentId': null,
              'minderId': null,
              'statementOriginalText': state.newTip.tipStatement,
              'statementStimulusText': state.newTip.tipStimulusQuestion,
              'statementType': 'feedbackOnCategory',
            } , {root: true})
        
        } else {
            alert('User not logged in; tip reading was not created')
        }
      })
    },
    async getTips({commit}, payload) {
      firebase.auth().onAuthStateChanged(async (user) => {  
        if(user) {
          await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('intent_mean_categories').doc(payload.intentMeanCategory).collection('userTips').orderBy('dateCreated', 'desc').onSnapshot(snap => {
          
          //await db.collection('next_steps').where("userUid", "==", user.uid).orderBy('dateCreated', 'desc').onSnapshot(snap => {
            //db.collection('minders').where("userUid", "==", user.uid).orderBy('dateCreated', 'desc').get().then((snap) => {
            commit(RESET_TIP_LIST)
            
            snap.forEach((doc) => {
              var timestamp = doc.data().dateCreated
              var dateCreated = timestamp.toDate() 
              commit(PUSH_TIP, {
                  'tip': {
                    'id': doc.id,
                    'timeCreated': timestamp,
                    'dateCreated': dateCreated,
                    ...doc.data()
                  },
                  
              })
            })
          })
        }
      })
    },
    
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }