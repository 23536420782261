import db from '@/db'

import {
    
    SET_CURRENT_CATEGORY,
    RESET_CURRENT_CATEGORY,
    SET_CURRENT_CONTEXT_CATEGORY,
    RESET_CURRENT_CONTEXT_CATEGORY,
    SET_CURRENT_PARENT_CATEGORY,
    RESET_CURRENT_PARENT_CATEGORY,
    SET_CURRENT_CTA_TYPE,
    SET_CURRENT_SPECIFICITY_LEVEL,

  } from './mutations'
  
  const state = {
    currentCategory: null,
    currentContextCategory: null,
    currentParentCategory: null,
    currentCtaType: null,
    currentSpecificityLevel: null,

    /*
    newCategory: {
      dateCompleted: null,
      dateCreated: null,
      dateUpdated: null,
      experienceState: null,
      meanCategory: null,
      minderId: null,
      momentId: null,
      categoryStatement: null,
      categoryStimulusQuestion: null, 
      categoryTheme: null,
    },
    categories: {
      0: [],
      1: [],
      2: [],
      3: [],
    },
    loading: false
    */
    
  }
  
  const getters = {
    currentCategory: ({currentCategory}) => currentCategory,
    currentContextCategory: ({currentContextCategory}) => currentContextCategory,
    currentParentCategory: ({currentParentCategory}) => currentParentCategory,
    currentCtaType: ({currentCtaType}) => currentCtaType,
    currentSpecificityLevel: ({currentSpecificityLevel}) => currentSpecificityLevel,
    //newCategory: ({newCategory}) => newCategory,
    //categories: ({categories}) => categories,
    //loading: ({loading}) => loading
    
  }
  
  const mutations = {
    [SET_CURRENT_CATEGORY] (state, category) {
      state.currentCategory = category
    },
    [RESET_CURRENT_CATEGORY] (state) {
      state.currentCategory = null
    },
    [SET_CURRENT_CONTEXT_CATEGORY] (state, category) {
      state.currentContextCategory = category
    },
    [RESET_CURRENT_CONTEXT_CATEGORY] (state) {
      state.currentContextCategory = null
    },
    [SET_CURRENT_PARENT_CATEGORY] (state, category) {
      state.currentParentCategory = category
    },
    [RESET_CURRENT_PARENT_CATEGORY] (state) {
      state.currentParentCategory = null
    },
    [SET_CURRENT_SPECIFICITY_LEVEL] (state, level) {
      state.currentSpecificityLevel = level
    },
    [SET_CURRENT_CTA_TYPE] (state, type) {
      state.currentCtaType = type
    },



    /*
    [SET_CATEGORY_LOADING] (state, bool) {
      state.loading = bool
    },
    [SET_CATEGORY] (state, payload) {
      state.newCategory.dateCompleted = payload.dateCompleted
      state.newCategory.dateCreated  = payload.dateCreated
      state.newCategory.dateUpdated  = payload.dateUpdated
      state.newCategory.experienceState  = payload.experienceState
      state.newCategory.meanCategory = payload.meanCategory
      state.newCategory.momentId  = payload.momentId
      state.newCategory.categoryStatement  = payload.categoryStatement
      state.newCategory.categoryStimulusQuestion  = payload.categoryStimulusQuestion
      state.newCategory.categoryTheme  = payload.categoryTheme
    },
    [RESET_CATEGORY] (state) {
      state.newCategory.dateCompleted = null
      state.newCategory.dateCreated  = null
      state.newCategory.dateUpdated  = null
      state.newCategory.experienceState  = null
      state.newCategory.meanCategory = null
      state.newCategory.momentId  = null
      state.newCategory.categoryStatement  = null
      state.newCategory.categoryStimulusQuestion  = null
      state.newCategory.categoryTheme  = null
    },
    [PUSH_CATEGORY] (state, payload) {
      state.categories.push(payload.category)
    },
    [REMOVE_CATEGORY] (state, payload) {
      if (state.categories.length >= 1) {
        state.categories.splice(payload.itemIndex, 1)
      }
    },
    [RESET_CATEGORY_LIST] (state) {
        state.categories = []
    } */
  }
  
  const actions = {
    async getValidCategory (_, payload) {
      return new Promise(function(resolve) {
        (async () => {
          var response = null
          await db.collection('taxonomies')
                  .doc('v1_2020_taxonomy')
                  .collection('intent_mean_categories')
                  .doc(payload)
                  .get()
                  .then((doc) => {
                    if(doc.exists) {
                      response = doc.data()
                      //console.log("response from getValidCategory()", response)

                    }
                  })
          resolve(response)
        })()
      })
    },
    async getParentCategoryData (_, payload) {
      return new Promise(function(resolve) {
        (async () => {
          var response = null
          await db.collection('taxonomies')
                  .doc('v1_2020_taxonomy')
                  .collection('intent_mean_parent_categories')
                  .doc(payload)
                  .get()
                  .then((doc) => {
                    if(doc.exists) {
                      response = doc.data()
                    }
                  })
          resolve(response)
        })()
      })
    },

    setCategoryOverlayData ({commit}, payload) {
      commit(SET_CURRENT_CATEGORY, payload.category)
      commit(SET_CURRENT_CONTEXT_CATEGORY, payload.contextCategory)
      commit(SET_CURRENT_PARENT_CATEGORY, payload.parentCategory)
      commit(SET_CURRENT_CTA_TYPE, payload.ctaType)
      commit(SET_CURRENT_SPECIFICITY_LEVEL, payload.specificityLevel)
    },

    async setCurrentCategory ({commit}, payload) {
      commit(RESET_CURRENT_CATEGORY)
      commit(SET_CURRENT_CATEGORY, payload)
    },
    async resetCurrentCategory ({commit}) {
      commit(RESET_CURRENT_CATEGORY)
    },
    async setCurrentContextCategory ({commit}, payload) {
      commit(RESET_CURRENT_CONTEXT_CATEGORY)
      commit(SET_CURRENT_CONTEXT_CATEGORY, payload)
    },
    async resetCurrentContextCategory ({commit}) {
      commit(RESET_CURRENT_CONTEXT_CATEGORY)
    },
    

    
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }