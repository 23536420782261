//CATEGORY MUTATIONS
export const PUSH_CATEGORY = "PUSH_CATEGORY"
export const RESET_CURRENT_CATEGORY = "RESET_CURRENT_CATEGORY"
export const RESET_CATEGORY_LIST = "RESET_CATEGORY_LIST"
export const SET_CATEGORY_LOADING = "SET_CATEGORY_LOADING"
export const SET_CATEGORY = "SET_CATEGORY"
export const SET_CURRENT_CATEGORY = "SET_CURRENT_CATEGORY"
export const REMOVE_CATEGORY = "REMOVE_CATEGORY"

export const SET_CURRENT_CONTEXT_CATEGORY = "SET_CURRENT_CONTEXT_CATEGORY"
export const RESET_CURRENT_CONTEXT_CATEGORY = "RESET_CURRENT_CONTEXT_CATEGORY"
export const SET_CURRENT_PARENT_CATEGORY = "SET_CURRENT_PARENT_CATEGORY"
export const RESET_CURRENT_PARENT_CATEGORY = "RESET_CURRENT_PARENT_CATEGORY"

export const SET_CURRENT_CTA_TYPE = "SET_CURRENT_CTA_TYPE"
export const SET_CURRENT_SPECIFICITY_LEVEL = "SET_CURRENT_SPECIFICITY_LEVEL"

